exports.components = {
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-backend-authorized-tsx": () => import("./../../../src/pages/backend/authorized.tsx" /* webpackChunkName: "component---src-pages-backend-authorized-tsx" */),
  "component---src-pages-courses-index-tsx": () => import("./../../../src/pages/courses/index.tsx" /* webpackChunkName: "component---src-pages-courses-index-tsx" */),
  "component---src-pages-jobs-application-[id]-tsx": () => import("./../../../src/pages/jobs/application/[id].tsx" /* webpackChunkName: "component---src-pages-jobs-application-[id]-tsx" */),
  "component---src-pages-jobs-reference-[id]-tsx": () => import("./../../../src/pages/jobs/reference/[id].tsx" /* webpackChunkName: "component---src-pages-jobs-reference-[id]-tsx" */),
  "component---src-pages-jobs-review-[job]-tsx": () => import("./../../../src/pages/jobs/review/[job].tsx" /* webpackChunkName: "component---src-pages-jobs-review-[job]-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-job-announcement-tsx": () => import("./../../../src/templates/job/announcement.tsx" /* webpackChunkName: "component---src-templates-job-announcement-tsx" */),
  "component---src-templates-job-application-form-tsx": () => import("./../../../src/templates/job/application-form.tsx" /* webpackChunkName: "component---src-templates-job-application-form-tsx" */),
  "component---src-templates-jupyter-notebook-tsx": () => import("./../../../src/templates/jupyter-notebook.tsx" /* webpackChunkName: "component---src-templates-jupyter-notebook-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

